import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import clsx from "clsx";
import { useNavigate } from "react-router";
import { Card } from "@mui/material";

interface PricingCardProps {
  id?: string;
  title?: string;
  subtitle?: string;
  price?: string;
  buttonTitle?: string;
  isPopular?: boolean;
  details?: React.ReactNode;
  className?: string;
  emphasis?: boolean;
  onSelect: (id: string) => void;
}

/**
 * The modern pricing card component with optional emphasis styling.
 */
function PricingCard(props: PricingCardProps) {
  const navigate = useNavigate();
  const {
    id = "",
    title = "",
    subtitle = "",
    price = "",
    buttonTitle = "",
    isPopular = false,
    details = "",
    className = "",
    emphasis = false,
    onSelect,
  } = props;

  return (
    <div className="relative">
      {emphasis && (
        <div className="absolute -top-6 left-1/2 transform -translate-x-1/2 z-10">
          <div className="bg-peeva-blue text-white px-6 py-2 rounded-full text-sm font-bold shadow-md">
            Best Value
          </div>
        </div>
      )}
      <Card 
        className={clsx(
          "p-[28px] overflow-hidden relative",
          emphasis && "bg-gradient-to-b from-blue-50 to-white border-2 border-peeva-blue shadow-lg",
          !emphasis && "bg-white",
          className
        )}
      >
        <Typography className="font-quicksand text-18px sm:text-22px leading-22px sm:leading-26px font-700 text-peeva-black tracking-tight">
          {title}
        </Typography>

        <Typography
          className="font-quicksand my-8 text-14px sm:text-16px text-peeva-black font-400 leading-21px sm:leading-22px tracking-tight"
          color="text.secondary"
        >
          {subtitle}
        </Typography>

        <div className="flex items-baseline whitespace-nowrap mt-20 justify-start">
          <Typography className={clsx(
            "font-quicksand text-22px sm:text-28px leading-26px sm:leading-34px font-700 tracking-tight",
            emphasis ? "text-peeva-blue" : "text-peeva-black"
          )}>
            ${price}
          </Typography>
        </div>

        {details}

        <div className="flex w-full justify-center">
          <Button
            onClick={() => onSelect(id)}
            variant="contained"
            color="secondary"
            className={clsx(
              "font-robotoslab w-full text-white sm:w-auto mt-32 sm:mt-16 h-48px sm:h-54px rounded-full transition-colors duration-200 py-[12px] sm:py-[16px] px-[24px] sm:px-[32px] sm:text-16px text-14 font-700 sm:leading-22px leading-16px float-right",
              emphasis 
                ? "bg-peeva-blue hover:bg-peeva-blue-110 border-2 border-peeva-blue"
                : "bg-peeva-blue hover:bg-peeva-blue-110 border"
            )}
            aria-label="Continue to payment"
            type="submit"
            size="large"
          >
            {buttonTitle}
          </Button>
        </div>
      </Card>
    </div>
  );
}

export default PricingCard;