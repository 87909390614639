import React, { useEffect } from 'react';
import { CardContent, Checkbox, Stack, Card, Typography } from "@mui/material";
import { peevaBlue } from '@fuse/colors';
import petService from '@fuse/services/petService';

export default function UpsellCheckout({
	isTagSelected,
	setIsTagSelected,
}) {
	const [tagPrice, setTagPrice] = React.useState(19.99);

	useEffect(() => {
		getTagPrice();
	});

	async function getTagPrice() {
		const { planPrice } = await petService.getCollarPrice() as any;
		if (planPrice) {
			setTagPrice(planPrice);
		}
	}

	return (
		<Card className="mb-8  py-7 mt-6">
			<CardContent>
				<div className="flex items-center space-x-4">
					<div className="flex-shrink-0 mr-[12px]">
						<img
							src="https://images.printify.com/mockup/66c76b36eb2e9cb9510bca9e/70870/11734?s=608&t=1724345150000"
							alt="Personalized QR Code Collar"
							className="w-80 h-80 rounded-md shadow-sm"
						/>
					</div>
					<div className="flex-grow space-y-5">
						<Typography className="font-quicksand text-lg sm:text-22px leading-32px sm:leading-26px font-700 text-peeva-black tracking-tight">
							Personalized QR Collar Tag
						</Typography>
						<Typography className="font-quicksand text-sm sm:text-22px leading-32px sm:leading-26px font-400 text-peeva-black tracking-tight">
							Available only for US Customers. 🇺🇸
						</Typography>
						<Typography
							className="font-quicksand my-8 text-14px sm:text-16px text-peeva-black font-400 leading-21px sm:leading-22px tracking-tight"
							color="text.secondary"
						>
							Enhance your pet's safety with a durable, weather-resistant collar featuring a unique QR code.
							When scanned, it provides instant access to your contact information and pet's details.
						</Typography>

						<div className="flex items-center py-3 rounded-lg">
							<Checkbox
								id="qr-collar"
								className="p-0 mr-[12px] w-4 h-4 "
								onClick={e => setIsTagSelected((e.target as any).checked)}
								value={isTagSelected}
								checked={isTagSelected}
								sx={{
									color: peevaBlue.DEFAULT,
									"&.Mui-checked": {
										color: peevaBlue.DEFAULT,
									},
								}}
							/>

							<label htmlFor="qr-collar" className="text-base font-medium text-sky-700 flex items-center cursor-pointer">
								Add for just
								<Typography className="font-quicksand flex items-center text-12px sm:text-18px leading-26px sm:leading-34px font-700 text-peeva-black tracking-tight ml-[4px]">
									${tagPrice}
									<Typography className="font-quicksand text-12px sm:text-14px font-normal text-sky-700 ml-2"> (Shipping included)</Typography>
								</Typography>
							</label>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	)
};
