import _ from "../../../../../../@lodash/@lodash";
import { useLayoutEffect, useState } from "react";
import petService from "@fuse/services/petService";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useParams } from "react-router";
import { IPetModel } from "@fuse/models";
import paymentService from "@fuse/services/paymentService";
import PlanType from "src/app/main/auth/types/plan.type";
import { Card, Typography } from "@mui/material";
import { motion } from "framer-motion";
import FuseLoading from "@fuse/core/FuseLoading";
import PricingCard from "src/app/main/auth/SignUpFlow/PetFlow/PricingCard";
import { IApiResponse } from "@fuse/models/user.models";
import HTMLReactParser from "html-react-parser/lib/index";
import { useAppSelector } from "app/store";
import { selectPeevaSettings } from "app/store/fuse/peevaSettings";
import UpsellCheckout from "@fuse/core/UpsellCheckout/UpsellCheckout";

function CheckoutPetApp() {
	const appSettings = useAppSelector(selectPeevaSettings);
	const params = useParams();
	const petId = params.id;
	const [customError, setCustomError] = useState("");
	const [loading, setLoading] = useState(false);
	const [plans, setPlans] = useState<PlanType[]>([]);
	const [pet, setPet] = useState<IPetModel>({} as IPetModel);
	const [isTagSelected, setIsTagSelected] = useState(false);

	const container = {
		show: {
			transition: {
				staggerChildren: 0.1,
			},
		},
	};

	const item = {
		hidden: { opacity: 0, y: 100 },
		show: { opacity: 1, y: 0 },
	};

	useLayoutEffect(() => {
		petService
			.getPetById(petId)
			.then((res: any) => {
				if (res.result) {
					setPet(res.result);
				}
			})
			.catch((error) => {
				console.log(error);
			});

		petService
			.getPetPlans()
			.then((res: any) => {
				if (res.result) {
					setPlans(res.result.plans);

					const plans = res.result.plans as Array<PlanType>;
					plans.forEach((p) => {
						selectedPlansPush(p);
					});

					onPlansView(plans);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	async function onCardSelect(id: string) {
		setLoading(true);
		const resp = (await petService.updatePetPlan(petId, {
			id: petId,
			planId: id,
		})) as IPetModel;

		if (resp.id) {
			const tagSelected = appSettings.enableQRcodeUpsell === 'true' ? isTagSelected : false;

			const checkout = (await paymentService.getCheckoutUrl(
				resp.id,
				true, tagSelected
			)) as IApiResponse;

			if (checkout.succeeded) {
				setLoading(false);
				handleDataLayerPush(id);

				location.href = checkout.result.location;
			} else {
				setCustomError(checkout.errors[0]);

				setTimeout(() => {
					setCustomError("");
					setLoading(false);
				}, 5000);
			}
		} else {
			setLoading(false);
		}
	}

	function handleDataLayerPush(id: string) {
		const plan = plans.find((f) => f.id == id);

		//@ts-ignore
		window.dataLayer = window.dataLayer || [];
		//@ts-ignore
		window.dataLayer.push({
			event: "add_to_cart",
			value: plan.planPrice,
			currency: plan.currency,
			items: [
				{
					item_id: plan.planId,
					item_name: plan.name,
					item_variant: plan.priceId,
					price: plan.planPrice,
				},
			],
		});
	}

	function onPlansView(plans: PlanType[]) {
		const plan = plans[0];
		const sum = plans.reduce((t, el) => t + el.planPrice, 0);
		const items = [];
		plans.forEach((p) => {
			items.push({
				item_id: p.planId,
				item_name: p.name,
				item_variant: p.priceId,
				price: p.planPrice,
			});
		});

		//@ts-ignore
		window.dataLayer = window.dataLayer || [];

		//@ts-ignore
		window.dataLayer.push({
			event: "view_item",
			currency: plan.currency,
			value: sum,
			items: [...items],
		});
	}

	function selectedPlansPush(plan: PlanType) {
		const experimentName = plan.oneTime ? "lifetime" : "yearly";
		//@ts-ignore
		window.dataLayer = window.dataLayer || [];

		//@ts-ignore
		window.dataLayer.push({
			event: "experiment_play",
			experiment_name: experimentName,
			experiment_variant: plan.planPrice,
		});
	}

	return (
		<>
			<div className="w-full p-[20px]">
				<div className="flex flex-col gap-[20px]">
					{customError && (
						<Stack className="pb-12" sx={{ width: "100%" }} spacing={2}>
							<Alert variant="filled" severity="error" onClose={() => { }}>
								<div>{HTMLReactParser(customError)}</div>
							</Alert>
						</Stack>
					)}
					{loading && <FuseLoading />}

					<div className="relative flex flex-auto flex-col overflow-hidden">
						<div className="relative overflow-hidden px-8 pb-8 pt-8 sm:px-16 sm:pb-8 sm:pt-8">
							<div className="mt-40 flex justify-center sm:mt-8">
								<div className="w-full">
									<motion.div
										variants={container}
										initial="hidden"
										animate="show"
										className="grid grid-cols-1 gap-y-[20px] md:grid-cols-2 md:gap-x-24 lg:grid-cols-2 lg:gap-y-0"
									>
										{plans.map((m, i) => {
											return (
												<motion.div variants={item} key={m.id}>
													{m.oneTime && (
														<PricingCard
															key={m.id}
															id={m.id}
															title="Lifetime Lost Pet Protection"
															subtitle="Pay once. Protect your pet for life."
															price={`${m.planPrice}`}
															buttonTitle="Select and continue"
															onSelect={onCardSelect}
															className="border"
															isPopular
															details={
																<div className="mt-16 flex flex-col">
																	<div className="flex flex-col text-xs font-400">
																		<div className="mt-8 space-y-4">
																			<div className="flex items-center">
																				<img
																					className="w-24 flex"
																					src="assets/img/24By7.svg"
																					alt="logo"
																				/>
																				<Typography className="ml-8 text-12px font-400 leading-14px">
																					24/7/365 Telephone support
																				</Typography>
																			</div>
																		</div>
																		<div className="mt-8 space-y-4">
																			<div className="flex items-center">
																				<img
																					className="w-24 flex"
																					src="assets/img/notification.svg"
																					alt="logo"
																				/>
																				<Typography className="ml-8 text-12px font-400 leading-14px">
																					Instant Notifications
																				</Typography>
																			</div>
																		</div>
																		<div className="mt-8 space-y-4">
																			<div className="flex items-center">
																				<img
																					className="w-24 flex"
																					src="assets/img/no-renewal.svg"
																					alt="logo"
																				/>
																				<Typography className="ml-8 text-12px font-400 leading-14px">
																					No Renewal Fee Required
																				</Typography>
																			</div>
																		</div>
																		<div className="mt-8 space-y-4">
																			<div className="flex items-center">
																				<img
																					className="w-24 flex"
																					src="assets/img/lifetime-microchip.svg"
																					alt="logo"
																				/>
																				<Typography className="ml-8 text-12px font-400 leading-14px">
																					Lifetime Microchip Registration
																				</Typography>
																			</div>
																		</div>
																	</div>
																</div>
															}
														/>
													)}
													{!m.oneTime && (
														<PricingCard
															key={m.id}
															id={m.id}
															price={`${m.planPrice}/year`}
															title="Yearly Lost Pet Protection"
															subtitle="Less than $5 per month billed annually"
															buttonTitle="Select and continue"
															onSelect={onCardSelect}
															details={
																<div className="mt-16 flex flex-col">
																	<div className="flex flex-col">
																		<div className="mt-6 space-y-4">
																			<div className="flex items-center">
																				<img
																					className="w-24 flex"
																					src="assets/img/24By7.svg"
																					alt="logo"
																				/>
																				<Typography className="ml-8 text-12px font-400 leading-14px">
																					24/7/365 Telephone support
																				</Typography>
																			</div>
																		</div>
																		<div className="mt-8 space-y-4">
																			<div className="flex items-center">
																				<img
																					className="w-24 flex"
																					src="assets/img/notification.svg"
																					alt="logo"
																				/>
																				<Typography className="ml-8 text-12px font-400 leading-14px">
																					Instant Notifications
																				</Typography>
																			</div>
																		</div>
																		<div className="mt-8 space-y-4">
																			<div className="flex items-center">
																				<img
																					className="w-24 flex"
																					src="assets/img/renewal.svg"
																					alt="logo"
																				/>
																				<Typography className="ml-8 text-12px font-400 leading-14px">
																					Yearly Renewal Required
																				</Typography>
																			</div>
																		</div>
																		<div className="md:mt-[3.5rem] sm:mt-24 space-y-8">
																			<div className="flex items-center"></div>
																		</div>
																	</div>
																</div>
															}
														/>
													)}
												</motion.div>
											);
										})}
									</motion.div>
								</div>
							</div>
						</div>
					</div>
					{
						!loading && plans.length > 0 && appSettings.enableQRcodeUpsell === 'true' && (<div className="px-8 sm:px-16">
							<motion.div variants={item} initial="hidden" animate="show">
								<UpsellCheckout isTagSelected={isTagSelected} setIsTagSelected={setIsTagSelected} />
							</motion.div>
						</div>)
					}

					<Typography className="font-robotoslab text-12px sm:text-14px font-400 leading-16px sm:leading-22px mt-10 px-20 text-center">
						Every second counts when a pet is missing. Listing your pet in a
						database is not enough. Your registration fee enables us to provide
						the cutting-edge technology and live phone support necessary to
						expedite their return home
					</Typography>
					<div className="grid grid-cols-1 mt-16 mb-32">
						<img
							className="flex mx-auto max-w-xl"
							src="assets/img/google-reviews.svg"
							alt="logo"
						/>
					</div>
					{/* <div className="flex justify-center gap-2 mt-14">
						<img className="w-92 flex" src="assets/img/secure.svg" alt="logo" />
						<img className="w-92 flex" src="assets/img/stripe.svg" alt="logo" />
					</div> */}
				</div>
			</div>
		</>
	);
}

export default CheckoutPetApp;
